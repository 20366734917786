import * as React from "react"
import Layout from "../components/layout";
import Emoji from "../components/emoji";
import {StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";

// markup
const AboutPage = () => {
  return (
    <Layout title="About">
      <div className="container mx-auto pt-12 pb-20 text-center max-w-2xl">
        <h1>Hello <Emoji emoji="👋" label="waving hand"/></h1>
        <h2 className="mt-2">We are Marten and Philipp.</h2>
        <div className="flex justify-center gap-8 my-8">
          <StaticImage
            className="w-44 rounded-full"
            src="../images/marten.jpg"
            alt="Marten"
            imgStyle={{ borderRadius: '100%' }}
            placeholder="blurred"/>
          <StaticImage
            className="w-44 rounded-full"
            src="../images/philipp.png"
            alt="Philipp"
            imgStyle={{ borderRadius: '100%' }}
            placeholder="blurred"/>
        </div>
        <h3 className="mb-1">We are the small team behind ScribbleX.</h3>
        <StaticImage className="w-28 mb-6" src="../images/pencil.svg" alt="pencil" placeholder="blurred"/>
        <p>
          The idea for the app was born on a rainy afternoon while playing a round of “Exquisite Corpse” with some friends.
          <br/><br/>
          Exquisite Corpse (or cadavre exquis) is an old surrealist creativity game that was invented way back in the 1920s. Of course back then you still had to use pen and paper to play.
          <br/><br/>
          Well, not anymore 👉 <strong>ScribbleX brings the exquisite corpse into the 21st century.</strong>
          <br/><br/>
          If you’ve got any ideas, questions or suggestions, <Link to="/contact">let us know</Link>!
        </p>
      </div>
    </Layout>
  )
}

export default AboutPage
